<template>
  <v-main>
    <Loader v-if="loaderState" />
    <template v-else>
      <div
        v-if="!selectedExecutions.length"
        class="view-page"
      >
        <WorkspaceHeader 
          v-if="filterData"
          :filter="filterView"
          :todo-count="todoCount"
          :progress-calculation="progressCalculation"
          :completed-count="completedCount"
          :filter-data="filterData"
          @updateView="updateView" 
          @update-filters="setFilters"
        />
        <v-card
          :elevation="0"
          class="mt-3 pa-3 text-center"
        >
          <div v-if="!executions.length">
            <EmptyWorkspace />
            <h2 class="text-center">
              {{ $t('noCasesYet') }}<span class="d-block">{{ $t('itsVibeHere') }}</span>
            </h2>
            <p class="text-center">
              {{ $t('waitingforcases') }}
            </p>
          </div>
          <v-row v-else>
            <v-col :cols="isDetailViewCollapsed ? 8 : 12">
              <List 
                :case-items="displayedExecutions"
                @expandDetail="openDetailView"
                @selectedExecutions="viewExecutions"
                @updateRows="updateRows"
              />
            </v-col>
            <v-col
              v-if="isDetailViewCollapsed && selectedExecution"
              cols="4"
            >
              <detail-view
                :execution="selectedExecution"
                :test-results="testResults"
                @closeDetail="closeDetailView"
                @updateExecution="updateTestExecution"
                @addResult="addTestResult"
                @updateResult="updateTestResult"
                @deleteResult="deleteTestResult"
                @moveItem="moveSelectedItem"
              />
            </v-col>
            <v-col
              v-if="selectedRows.length > 1"
              cols="12"
              class="d-flex justify-end"
            >
              <v-menu
                top
                offset-y
                :nudge-top="4"
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    dark
                    large
                    color="blue darken-3"
                    class="text-capitalize font-weight-bold white--text mr-4 mt-2"
                    :width="$vuetify.breakpoint.smAndDown ? '100%' : '150px'"
                    v-on="on"
                  >
                    {{ $t('actions') }}<v-icon>
                      {{ menuOpen ? 'mdi-chevron-up' :
                        'mdi-chevron-down' }}
                    </v-icon>
                  </v-btn>
                </template>
                <v-list
                  dense
                  class="text-left"
                >
                  <v-list-item
                    @click="multiAddResults"
                  >
                    <v-icon
                      class="pr-2"
                    >
                      mdi-plus
                    </v-icon>{{ $t('addResult') }}
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
        </v-card>
      </div>
      <div
        v-else
        class="execution-page"
      >
        <v-row class="my-3">
          <v-col
            cols="12"
            class="py-0"
          >
            <div class="__execution-top-header white py-4 px-3 d-flex align-center justify-between">
              <div class="__top-header-left d-flex">
                <v-btn
                  icon
                  @click="resetExecutions"
                >
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                <h2 class="__execution_name">
                  {{ selectedExecution.name }}
                </h2>
              </div>
              <div
                v-if="selectedExecutions.length > 1"
                class="__top-header-right d-flex align-center"
              >
                <v-btn
                  v-if="currentExecution > 1"
                  :elevation="0"
                  small
                  @click="moveExecution('previous')"
                >
                  <v-icon>mdi-chevron-left</v-icon>
                  <span>{{ $t('previous') }}</span>
                </v-btn>
                <p class="mb-0 mx-2">
                  {{ currentExecution }} of {{ selectedExecutions.length }}
                </p>
                <v-btn
                  v-if="currentExecution < selectedExecutions.length"
                  :elevation="0"
                  small
                  @click="moveExecution('next')"
                >
                  <span>{{ $t('next') }}</span>
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </div>
            </div>
          </v-col>
          <v-col cols="4">
            <detail-view
              :execution="selectedExecution"
              :test-results="testResults"
              :show-actions="false"
              :show-name="false"
              @closeDetail="closeDetailView"
              @updateExecution="updateTestExecution"
              @addResult="addTestResult"
              @updateResult="updateTestResult"
              @deleteResult="deleteTestResult"
              @moveItem="moveSelectedItem"
            />
          </v-col>
          <v-col
            cols="8"
            class="pl-0"
          >
            <v-card
              :elevation="0"
              class="pa-3"
            >
              <h2 class="__execution_name result-execution mb-4">
                {{ selectedExecution.name }}
              </h2>
              <v-form
                ref="form"
                class="text-left"
              >
                <label class="font-weight-medium d-block result-form-label mb-1">{{ $t('status') }}</label>
                <v-select 
                  v-model="resultState" 
                  placeholder="Select status" 
                  class="rounded-lg mb-4" 
                  :rules="requiredRule"
                  :items="resultStatus" 
                  :hide-details="true" 
                  dense 
                  filled  
                  :menu-props="{'offset-y': true}"
                />
                <label class="font-weight-medium d-block result-form-label mb-1">{{ $t('addComment') }}</label>
                <v-tiptap
                  v-model="resultComment"
                  :toolbar="customToolbar"
                />
                <!-- <v-tiptap v-model="resultComment" view /> -->
                <div class="result-attachments">
                  <label class="font-weight-medium d-block result-form-label mb-1">{{ $t('addAttachment') }}</label>
                  <label
                    id="input-file-browser"
                    for="input-file"
                    class="mb-4"
                  >
                    <div>
                      <p>{{ $t('browseFile') }}</p>
                    </div>
                  </label>
                  <v-file-input
                    id="input-file"
                    v-model="files"
                    class="d-none"
                    multiple
                    loading
                    counter
                  />
                  <!-- New Attachments -->
                  <div class="step-item-attachments d-flex flex-column align-start">
                    <div
                      v-for="(item,index) in files"
                      :key="index"
                      class="item-attachment"
                    >
                      <v-btn
                        text
                        class="pa-4"
                        small
                      >
                        <v-icon>mdi-file-document-outline</v-icon>
                        <span>{{ item.name }}</span>
                      </v-btn>
                      <v-btn
                        icon
                        small
                        color="red"
                        @click="deleteAttachment(index)"
                      >
                        <v-icon small>
                          mdi-close
                        </v-icon>
                      </v-btn>
                    </div>
                  </div>
                </div>
                <div class="d-flex justify-end">
                  <v-btn
                    dark
                    :elevation="0"
                    color="gray-100"
                    class="text-capitalize black--text mr-4 mt-2"
                  >
                    {{ $t('clear') }}
                  </v-btn>
                  <v-btn
                    dark
                    :elevation="0"
                    color="blue"
                    class="text-capitalize mt-2"
                    @click="addResult"
                  >
                    {{ $t('addResult') }}
                  </v-btn>
                </div>
              </v-form>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </template>
  </v-main>
</template>
<script>
import WorkspaceHeader from '@/components/Workspace/WorkspaceHeader'
import EmptyWorkspace from '@/assets/svg/empty-workspace.svg'
import workspaceService from '@/services/api/workspace'
import List from '@/components/Workspace/List'
import makeResultService from '@/services/api/result'
import makeExecutionService from '@/services/api/execution'
import DetailView from '@/components/Execution/Index.vue'
import { showSuccessToast, showErrorToast } from '@/utils/toast';
import { resultStatus } from '@/constants/results.js'
import { mapActions } from 'vuex'
import { sleep } from '@/utils/util';
import Loader from '@/components/base/Loader'

export default{
  components: {
    WorkspaceHeader,
    EmptyWorkspace,
    List,
    Loader,
    DetailView
  },
  data(){
    return {
      loading: false,
      filterData: null,
      filterView: 'todo',
      menuOpen: false,
      selectedFilters: null,
      executions: [],
      selectedExecution: null,
      isDetailViewCollapsed: false,
      testResults: [],
      selectedExecutions: [],
      selectedRows: [],
      customToolbar: [ 'h2', 'italic','p','bold','link', 'underline', 'headings', '|' ,'left', 'right' ],
      files: [],
      resultStatus,
      tableLoadingState: false,
      loaderState: false,
      resultState: null,
      resultComment: null,
      requiredRule: [
        v => !!v || 'This is required',
      ],
    }
  },
  computed:{
    progressCalculation(){
      const progress = {
        passed: 0,
        failed: 0,
        incomplete: 0
      }
      let inUsed = 0; 
      const executionsCount = this.executions.length;
      this.executions.forEach(item => { 
        if(item.status.toLowerCase() !== 'new'){
          inUsed += 1
          progress[item.status.toLowerCase()] += 1;
        }
      })

      const percentage = (inUsed / executionsCount * 100).toFixed(0);

      return {
        progress,
        percentage,
        length: this.executions.length
      }
    },
    currentExecution(){
      return this.selectedExecutions.findIndex(element => element.uid == this.selectedExecution.uid) + 1
    },
    todoCount(){
      return this.executions.filter(element => element.status.toLowerCase() !== "passed").length
    },
    completedCount(){
      return this.executions.filter(element => element.status.toLowerCase() == "passed").length
    },
    displayedExecutions(){
      return this.executions.filter((element) => {
        const status = element.status.toLowerCase();
        if (this.filterView === 'completed')
          return status === 'passed';
        else (this.filterView === 'todo') 
          return status !== 'passed';
  });

  }
  },
  methods:{
    ...mapActions({
      uploadToServer: 'attachment/uploadToServer',
    }),
    moveExecution(direction){
      const currentIndex = this.selectedExecutions.findIndex(element => element.uid == this.selectedExecution.uid);
      if(direction == 'next')
        this.selectedExecution = this.selectedExecutions[currentIndex + 1]
      else if(direction == 'previous')
        this.selectedExecution = this.selectedExecutions[currentIndex - 1]
      
      const projectKey = this.selectedExecution.projectKey;
      const uid = this.selectedExecution.uid;
      this.getExecutionResults(projectKey, uid)
    },
    resetExecutions(){
      this.selectedExecutions = [];
      this.selectedExecution = null;
      this.isDetailViewCollapsed = false;
    },
    addResult(){
      if(this.$refs.form.validate()){
        const payload = {
          files: this.files,
          status: this.resultState.toLowerCase(),
          comment: this.resultComment
        }
        this.addTestResult(payload)
        this.getExecutionResults(this.selectedExecution.projectKey, this.selectedExecution.uid)
  
        this.files = [];
        this.resultComment = null
        this.resultState = null;
      }
    },
    updateRows(rows){
      this.selectedRows = rows
    },
    multiAddResults(){
      this.viewExecutions(this.selectedRows)
    },
    async getExecutions(){
      const makeWorkspaceService = workspaceService(this.$api);
      const handle = this.$route.params.handle;
      await makeWorkspaceService.getWorkspaceExecutions(handle, this.selectedFilters).then(response => {
        this.executions = response.data
      });
    },
    updateView(data){
      this.filterView = data
    },
    async viewExecutions(selectedExecutions){
      this.selectedExecutions.push(...selectedExecutions)
      this.selectedExecution = this.selectedExecutions[0];
    },
    async getOverview(){
      const makeWorkspaceService = workspaceService(this.$api);
      const handle = this.$route.params.handle;
      await makeWorkspaceService.getWorkspaceOverview(handle).then(response => {
        this.filterData = response.data
      });
    },
    async setFilters(filters){
      this.selectedFilters = filters
      await this.getExecutions();  
    },
    async getExecutionResults(projectKey, executionUid){
      const handle = this.$route.params.handle;
      const resultService = makeResultService(this.$api);
      this.testResults = await resultService.getTestResults(handle, projectKey, executionUid).then(async response => {
        return response.data   
      }) 
    },
    closeDetailView() {
      this.isDetailViewCollapsed = false;
    },
    async openDetailView(item) {
      this.selectedExecution = item;
      this.isDetailViewCollapsed = true;
      await this.getExecutionResults(item.projectKey, item.uid)
    },
    async moveSelectedItem(direction){
      let itemIndex = this.displayedExecutions.findIndex(element => element.uid == this.selectedExecution.uid)
      if(itemIndex == -1){
        this.selectedExecution = null;
        this.isDetailViewCollapsed = false;
      }
      if(direction == 'next' && itemIndex < this.displayedExecutions.length-1)
        this.selectedExecution = this.displayedExecutions[itemIndex + 1]
      else if(direction == 'previous' && itemIndex > 0)
        this.selectedExecution = this.displayedExecutions[itemIndex - 1]

      await this.getExecutionResults(this.selectedExecution.projectKey,this.selectedExecution.uid)
    },
    async addTestResult(data){
      const resultService = makeResultService(this.$api);
      const relatedTo = 'result';
      const handle = this.$route.params.handle;
      const projectKey = this.selectedExecution.projectKey;
      const selectedExecution = this.selectedExecution.uid;
      const mediaType = 'attachment';
      const payload = {
        status: data.status,
        comment: data.comment
      };

      await resultService.addTestResult(handle, projectKey, selectedExecution, payload).then(async (response) => {
        const result = response.data;
        if(data.files.length)
         await Promise.all(data.files.map(async (file) => {
          await this.uploadToServer( {handle, mediaType, relatedTo, relatedToUid:result.uid, file });
        })).then(() => {
          showSuccessToast(this.$swal, this.$t('success.testResultAdded'))
        }).catch(() => {
          showErrorToast(this.$swal, this.$t('error.failedToAddTestResult'))
        })
        else
          showSuccessToast(this.$swal, this.$t('success.testResultAdded'))
      }).catch(() => {
        showErrorToast(this.$swal, this.$t('error.failedToAddTestResult'))
      })
      
      await this.getExecutionResults(projectKey,selectedExecution)
    },
    async updateTestResult(resultUid,data){
      const resultService = makeResultService(this.$api);
      const handle = this.$route.params.handle;
      const relatedTo = 'result';
      const projectKey = this.selectedExecution.projectKey;

      const payload = {
        status: data.status,
        comment: data.comment
      }
      
      await resultService.updateTestResult(handle, projectKey, resultUid, payload).then(async () => {
        const resultIndex = this.testResults.findIndex(element => element.resultUid == resultUid)
        this.testResults[resultIndex].status = payload.status[0].toUpperCase()+payload.status.slice(1)
        this.testResults[resultIndex].comment = payload.comment;
        if(data.files.length)
          await Promise.all(data.files.map(async (file) => {
            await this.uploadToServer({handle, relatedTo, relatedToUid: resultUid, file});
          })).then(() => {
            showSuccessToast(this.$swal, this.$t('success.testResultUpdated'))
          }).catch(() => {
            showErrorToast(this.$swal, this.$t('success.testResultUpdated'))
          })
        else
          showSuccessToast(this.$swal, this.$t('success.testResultUpdated'))
      }).catch(() => {
        showErrorToast(this.$swal, this.$t('success.testResultUpdated'))
      })
    },
    async deleteTestResult(resultUid){
      const resultService = makeResultService(this.$api);
      const handle = this.$route.params.handle;
      const projectKey = this.selectedExecution.projectKey;
      
      await resultService.deleteTestResult(handle, projectKey, resultUid).then(() => {
        showSuccessToast(this.$swal, this.$t('success.testResultDeleted'));
        const resultIndex = this.testResults.findIndex(element => element.resultUid == resultUid)
        this.testResults.splice(resultIndex,1)
      }).catch( () => {
        showErrorToast(this.$swal, this.$t('error.failedToDeleteTestResult'))
      } )
    },
    async updateTestExecution(updatedData){
      const executionService = makeExecutionService(this.$api);
      const handle = this.$route.params.handle;
      const projectKey = this.selectedExecution.projectKey;
      const selectedExecution = this.selectedExecution.uid;
      let payload = {};
      
      if(typeof(updatedData.value) == 'object')
        Object.assign(payload, {
          [updatedData.property] : updatedData.value.uid
        })
      else
        Object.assign(payload, {
          [updatedData.property] : updatedData.value
        })
      
      const findIndex = this.executions.findIndex(element => element.uid == this.selectedExecution.uid);    

      await executionService.updateExecution(handle,projectKey,selectedExecution,payload).then(() => {
        this.executions = this.executions.map((item, index) => {
          if(index == findIndex){
            this.selectedExecution = {
              ...this.selectedExecution,
              [updatedData.property] : updatedData.value
            }
            return {
              ...this.selectedExecution,
            }
          }
          return {
            ...item
          }
        })
        showSuccessToast(this.$swal, this.$t('success.executionUpdated'))
      }).catch(() => {
        showErrorToast(this.$swal, this.$t('error.executionUpdateFailed'))
      })
    },
  },
  async mounted(){
   await this.init([
      this.getOverview(),
      this.getExecutions()
   ])
  }
}
</script>
<style scoped>
#input-file-browser{
  display: block;
  height: 120px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #D0D5DD;
  background-color: #F9FAFB;
  color: #0C2FF3;
  font-weight: bold;
  cursor: pointer;
}
#input-file-browser:hover{
  background-color: #eeeeee;
  transition: all 0.5s;
}
h2.__execution_name{
  color: #101828;
  font-weight: 500;
  font-size: 24px;
  font-weight: 600;
}
h2.result-execution{
  font-size: 20px;
}
</style>